<template>
	<div id="body">
		<div id="back">
			<i @click="$router.back()" class="el-icon-arrow-left"></i>
		</div>
		<div id="img">
			<img :src="detail.Url">
		</div>
		<div id="dta">
			<p>{{detail.Name}}</p>
			<span>￥{{detail.Price}}<span
									style="font-size: 12px; color: #888888;font-weight: normal;"> 起</span></span>
		</div>
		<div id="btn">
			<button @click="Phone_payfun" type="button">立即购买</button>
		</div>
	</div>
</template>

<script>
	const axi = require('../../assets/axi.js')
	export default {
		data() {
			return {
				detail: {},
				prlist: [],
				num: 1
			}
		},
		mounted() {
			console.log(this.$store.state.shopid)
			this.detail=this.$store.state.shopid
			// for (let i = 0; i < this.prlist.length; i++) {
			// 	if (this.prlist[i].id == this.$store.state.shopid) {
			// 		this.detail = this.prlist[i]
			// 		console.log(this.detail)
			// 	}
			// }
		},
		methods: {
			Phone_payfun() {
				axi.post('Commodity/CommodityScaleDetails?Type='+this.detail.Type).then(res=>{
					console.log(res)
					if(res.data.Code==1000){
						this.detail.Slave=res.data.Dto
						this.$store.commit('shopidfun',this.detail)
						this.$router.push('/information')
					}else{
						this.$message.error(res.data.Msg)
					}
				})
				// this.$router.push('/information')
				// var ua = window.navigator.userAgent.toLowerCase();
				// if (ua.match(/MicroMessenger/i) == 'micromessenger') {
				// 	return true;
				// } else {
				// 	return false;
				// }
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	#back {
		height: 45px;
		font-size: 30px;
		position: fixed;
		top: 50px;
	}

	#img {
		width: 100%;
		height: 40vh;
		margin-bottom: 5%;
		text-align: center;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	#dta {
		width: 90%;
		margin-left: 5%;

		p {
			font-size: 26px;
		}

		span {
			color: #fd3f31;
			font-size: 22px;
		}
	}

	#btn {
		width: 100%;
		position: fixed;
		bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;

		button {
			width: 50%;
			height: 50px;
			background-color: #fd3f31;
			color: #FFFFFF;
			border-radius: 25px;
		}
	}
</style>
